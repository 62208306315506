import React, { useMemo } from 'react';
import SettingsIcon from '@atlaskit/icon/core/settings';
import AddIcon from '@atlaskit/icon/utility/add';
import { MenuGroup, Section, LinkItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { MODAL_ID } from '@atlassian/jira-atlassian-navigation/src/common/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { getProjectsNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/projects/index.tsx';
import { ChangeViewMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/change-view-menu-item/index.tsx';
import { ViewOptions } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/change-view-menu-item/view-options/constants.tsx';
import type { ViewOptionsType } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/change-view-menu-item/view-options/types.tsx';
import { useProjectsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l1/index.tsx';
import { useProjectsAnalyticsL2 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l2/index.tsx';
import { useProjectsAnalyticsL3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l3/index.tsx';
import {
	STARRED_AND_RECENT_PROJECTS,
	STARRED_PROJECTS_ONLY,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	type OnCloseProps,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { useStopPropagation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-stop-propagation/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { MoreNavMenuButtonMenuItemsProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/submenu/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import messages from './messages.tsx';

type ActionButtonsProps = {
	canCreateProject: boolean;
} & OnCloseProps;

export function ActionButtons({ onClose, canCreateProject }: ActionButtonsProps) {
	const { formatMessage } = useIntl();
	const [, { on: openProjectCreateDrawer }] = useSwitchModals(MODAL_ID.PROJECT_CREATE);
	const { sendMeatBallDropDownAnalyticsEvent, sendCreateProjectAnalyticsEvent } =
		useProjectsAnalyticsL1();

	return (
		<>
			{canCreateProject ? (
				<Tooltip content={formatMessage(messages.createProject)}>
					<CommonNavMenuButton
						icon={AddIcon}
						label={formatMessage(messages.createProject)}
						onClick={() => {
							openProjectCreateDrawer();
							sendCreateProjectAnalyticsEvent();
						}}
					/>
				</Tooltip>
			) : null}
			<MoreNavMenuButton
				onOpen={() => {
					sendMeatBallDropDownAnalyticsEvent();
				}}
				MenuItems={MoreMenuItems}
				onClose={onClose}
			/>
		</>
	);
}

function MoreMenuItems({ onClose }: MoreNavMenuButtonMenuItemsProps) {
	const { formatMessage } = useIntl();
	const href = useMemo(() => getProjectsNavigationPaths().projectsViewProjects, []);

	const { sendMeatBallDropDownManageProjectsAnalyticsEvent, sendChangeViewAnalyticsEvent } =
		useProjectsAnalyticsL2();

	const { sendChangeViewViewOptionAnalyticsEvent } = useProjectsAnalyticsL3();

	const onCloseWithStopPropagation = useStopPropagation(
		(e: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
			sendMeatBallDropDownManageProjectsAnalyticsEvent();
			onClose?.(e);
		},
	);

	const onChangeViewOptionChange = (viewOptionValue: ViewOptionsType) => {
		const itemId =
			viewOptionValue === ViewOptions.STARRED ? STARRED_PROJECTS_ONLY : STARRED_AND_RECENT_PROJECTS;

		sendChangeViewViewOptionAnalyticsEvent({ itemId });
	};

	return (
		<MenuGroup>
			<Section isList>
				<LinkItem
					iconBefore={<SettingsIcon label="" spacing="spacious" color={token('color.icon')} />}
					href={href}
					onClick={onCloseWithStopPropagation}
				>
					{formatMessage(messages.manageProjects)}
				</LinkItem>
			</Section>
			{expVal('blu-6315-display-settings-a-part-1', 'isEnabled', false) ? (
				<Section hasSeparator>
					<ChangeViewMenuItem
						onOpen={sendChangeViewAnalyticsEvent}
						onChange={onChangeViewOptionChange}
					/>
					<HideL1MenuItemSection hasSeparator={false} menuId={L1_MENU_ID.PROJECTS} />
				</Section>
			) : (
				<HideL1MenuItemSection menuId={L1_MENU_ID.PROJECTS} />
			)}
		</MenuGroup>
	);
}
