export const PACKAGE_NAME = 'jiraNativeIssueTable';
export const TEAM_NAME = 'empanada';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';
export const SORT_NONE = 'NONE';

// Modification to the list of visible columns, either by adding or removing columns
export const MODIFY_COLUMNS = 'MODIFY_COLUMNS';
// Reordering of columns via a drag & drop operation
export const REORDER_COLUMNS = 'REORDER_COLUMNS';

// Target positions when moving columns
export const MOVE_COLUMN_FIRST = 'MOVE_COLUMN_FIRST';
export const MOVE_COLUMN_LEFT = 'MOVE_COLUMN_LEFT';
export const MOVE_COLUMN_RIGHT = 'MOVE_COLUMN_RIGHT';
export const MOVE_COLUMN_LAST = 'MOVE_COLUMN_LAST';

export const BORDER_WIDTH = '1px';

export const HEADER_HEIGHT = 40;
export const ROW_HEIGHT = 40;

export const COLUMN_ID_MEATBALL_MENU = '__issuetable_internal_meatball';
export const COLUMN_ID_EXPAND_BUTTON = '__issuetable_internal_expand_button';
export const COLUMN_ID_CHECKBOX = '__issuetable_internal_checkbox';
export const COLUMN_ID_INTERNAL_COLUMN = '__issuetable_internal';

export const ACTION_COLUMNS = [
	COLUMN_ID_EXPAND_BUTTON,
	COLUMN_ID_MEATBALL_MENU,
	COLUMN_ID_CHECKBOX,
];

export const EXPAND_BUTTON_WIDTH = 24;
export const CREATE_CHILD_BUTTON_WIDTH = 24;
export const EXPAND_CELL_PADDING = 8 * 2;
export const DEPTH_WIDTH = 24;

// LDR: https://hello.atlassian.net/wiki/spaces/JFE/pages/2625316871/LDR+Maximum+number+of+columns+to+allow+in+NIN+in+M7.3
export const MAX_COLUMNS = 500;
export const CHILD_QUERY_PAGE_SIZE = 50;

export const KNOWN_COLUMN_TYPES = {
	ASSIGNEE: 'assignee',
	CHECKBOX: '__issuetable_internal_checkbox',
	CHECKBOX_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes',
	DESCRIPTION: 'description',
	ENVIRONMENT: 'environment',
	EXPAND_BUTTON: '__issuetable_internal_expand_button',
	ISSUE_KEY: 'issuekey',
	ISSUE_OPERATIONS: '__issuetable_internal_meatball',
	STATUS: 'status',
	SUMMARY: 'summary',
	ISSUE_TYPE: 'issuetype',
	RESOLUTION: 'resolution',
	PARENT: 'parent',
	LABELS: 'labels',
	COMPONENTS: 'components',
	FIX_VERSIONS: 'fixVersions',
	STATUS_CATEGORY: 'statusCategory',
	PROJECT: 'project',
	READ_ONLY: 'com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield',
	SPRINT: 'com.pyxis.greenhopper.jira:gh-sprint',
	TEXT: 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
	EPIC_LINK: 'com.pyxis.greenhopper.jira:gh-epic-link',
	CREATED: 'created',
	RESOLUTION_DATE: 'resolutiondate',
	TEXT_AREA: 'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
	UPDATED: 'updated',
	DUE_DATE: 'duedate',
	REPORTER: 'reporter',
	CREATOR: 'creator',
	PRIORITY: 'priority',
	AFFECTS_VERSIONS: 'versions',
	REQUEST_TYPE: 'com.atlassian.servicedesk:vp-origin',
	NUMBER: 'com.atlassian.jira.plugin.system.customfieldtypes:float',
	STORY_POINT_ESTIMATE: 'com.pyxis.greenhopper.jira:jsw-story-points',
	USER_PICKER: 'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
	CUSTOM_LABELS: 'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	DATE_TIME: 'com.atlassian.jira.plugin.system.customfieldtypes:datetime',
	URL: 'com.atlassian.jira.plugin.system.customfieldtypes:url',
	SINGLE_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:select',
	MULTI_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
	MULTI_USER_PICKER: 'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
	TEAM: 'com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team',
	ORIGINAL_ESTIMATE: 'timeoriginalestimate',
} as const;

export const COLUMN_TYPE_EPIC_LINK = 'com.pyxis.greenhopper.jira:gh-epic-link';
export const COLUMN_TYPE_PARENT_LINK = 'com.atlassian.jpo:jpo-custom-field-parent';
export const COLUMN_TYPE_DATE_PICKER =
	'com.atlassian.jira.plugin.system.customfieldtypes:datepicker';
export const COLUMN_TYPE_SATISFACTION_FEEDBACK = 'com.atlassian.servicedesk:sd-request-feedback';
export const COLUMN_TYPE_SENTIMENT = 'com.atlassian.servicedesk.sentiment:sd-sentiment';

export const NATURAL_LANGUAGE_TO_JQL = 'NATURAL_LANGUAGE_TO_JQL';

// Column Widths
export const MINIMUM_COLUMN_WIDTH_COMPACT = 40; // 2 x 8px cell padding + 24px icon size
export const MINIMUM_COLUMN_WIDTH = 69; // 2 x 8px cell padding + 2 x 24px icon size + 4px gap + 1px border
export const DEFAULT_COLUMN_WIDTH = 170;
export const MAXIMUM_COLUMN_WIDTH = 999;

// Column Widths for specific column types
export const MINIMUM_DATETIME_COLUMN_WIDTH = 148;
export const MINIMUM_MULTI_USER_SELECT_COLUMN_WIDTH = 136;
export const MINIMUM_DATEPICKER_COLUMN_WIDTH = 110;
export const MINIMUM_SATISFACTION_COLUMN_WIDTH = 102;
export const MINIMUM_SENTIMENT_COLUMN_WIDTH = 84;

export const COLUMN_WIDTH_TEXT_LONG = 400;
export const COLUMN_WIDTH_TEXT_SHORT = 300;
export const COLUMN_WIDTH_TEXT_SUMMARY = 450;

// Column operations
export const COLUMN_OPERATION_REORDER = 'reorder';
export const COLUMN_OPERATION_RESIZE = 'resize';
export const INSERT_COLUMN_BUTTON_Y_OFFSET = -12;

// Column resize handle
export const COLUMN_RESIZE_HANDLE_FULL_HEIGHT = 'FULL_HEIGHT';
export const COLUMN_RESIZE_HANDLE_HEADER_ONLY = 'HEADER_ONLY';

// Row operations
export const ROW_OPERATION_REORDER = 'rowReorder' as const;

export const DENSITY_COMPACT = 'COMPACT';
export const DENSITY_FULL = 'FULL';

export const LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE = 'LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE';
export const LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE =
	'LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE';

export const CHILD_ITEMS_EXPERIENCE = 'loadChildItems';

export const CHILD_ITEMS_LOAD_MORE_EXPERIENCE = 'loadMoreChildItems';

export const GROUPED_ITEMS_EXPERIENCE = 'loadGroupChildren';

export const GROUPED_LOAD_MORE_EXPERIENCE = 'loadMoreGroupedItems';

export const INLINE_ISSUE_CREATE_EXPERIENCE = 'createIssue';

export const ANALYTICS_SOURCE = 'jira-native-issue-table' as const;

export const MAKE_CHILD = 'make-child';
export const REORDER_ABOVE = 'reorder-above';
export const REORDER_BELOW = 'reorder-below';

export const SUBTASK_HIERARCHY_LEVEL = -1;

export const COLUMN_PICKER_CONFIGURATION_CONTAINER = 'COLUMN_PICKER_CONFIGURATION_CONTAINER';
export const COLUMN_PICKER_ADD_COLUMN_CONTAINER = 'COLUMN_PICKER_ADD_COLUMN_CONTAINER';
