/**
 * @generated SignedSource<<858f250907ea7d406961cdc5ed8ede66>>
 * @relayHash a0ed5cb06d870b10ddc16f342565e07d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae81a5ad94cf1a950b73b3b3918011754578e327b1fb797b6bfe14fffa615e79

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ae81a5ad94cf1a950b73b3b3918011754578e327b1fb797b6bfe14fffa615e79",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
