import React, { forwardRef, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import urlParse from 'url-parse';
import type { CSSFn } from '@atlaskit/menu';
import { CustomItem, type CustomItemComponentProps } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { useRouter, Link as RouterLink, type LinkProps } from '@atlassian/react-resource-router';
import { useSidebarTheme } from '../../../controllers/sidebar-theme/index.tsx';
import type { LegacyEmotionCssObject } from '../../../types.tsx';
import type { MenuItemProps } from './types.tsx';
import { getCustomThemeCss } from './utils/css-fn-utils/custom-theme-css-fn.tsx';
import { useOnClick } from './utils/on-click/main.tsx';

type WrapperComponentProps = CustomItemComponentProps &
	LinkProps & {
		legacyClassName?: string;
	};

const WrapperComponent = forwardRef<HTMLAnchorElement | HTMLButtonElement, WrapperComponentProps>(
	(
		{
			children,
			className,
			legacyClassName,
			href,
			to,
			target,
			// @ts-expect-error - TS2339 - Property 'useButtonLink' does not exist on type 'CustomItemComponentProps & AnchorHTMLAttributes<HTMLAnchorElement> & { children: ReactNode; ... 10 more ...; prefetch?: false | ... 2 more ... | undefined; } & { ...; }'.
			useButtonLink,
			...props
		},
		ref,
	) => {
		const commonProps = {
			...props,
			children,
			className: `${className ?? ''} ${legacyClassName ?? ''}`,
		};

		return href != null || to != null ? (
			<RouterLink
				{...commonProps}
				href={href}
				to={to}
				target={target}
				ref={ref}
				type={useButtonLink ? 'button' : 'a'}
			/>
		) : (
			// @ts-expect-error Type 'HTMLAnchorElement | HTMLButtonElement | null' is not assignable to type 'HTMLButtonElement | null'
			<ButtonItem {...commonProps} type="button" ref={ref} />
		);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyState = any;
const customCssFn: CSSFn<AnyState> = (currentState = {}): LegacyEmotionCssObject => ({
	'[data-item-title]': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 1.2,
	},

	/* fixes CSS leaking from the monolith */
	...(currentState.isSelected && {
		'& [data-item-title]': {
			color: token('color.text.selected'),
		},
	}),

	/* fixes CSS leaking from the monolith */
	'&:focus, &:visited, &:active': {
		textDecoration: 'inherit',
		outline: 0,
	},

	/* fixes CSS leaking from the monolith */
	'&:active': {
		backgroundColor: token('color.background.selected.pressed'),
		boxShadow: token('elevation.shadow.overlay'),

		'& [data-item-title]': {
			color: token('color.text'),
		},
	},

	'&:focus': {
		boxShadow: `${token('color.border.focused')} 0 0 0 2px inset`,
	},

	/* fixes CSS for react-beautiful-dnd */
	'&[data-rbd-draggable-id]': {
		cursor: 'pointer',
	},
});

export const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
	(
		{
			analytics,
			href = undefined,
			onClick,
			overrides,
			selectedOn,
			cssFn: outsideCssFn,
			useButtonLink = false,
			...rest
		},
		ref,
	) => {
		const onClickHandler = useOnClick({ onClick, analytics });
		const [{ route, query, location }] = useRouter();
		let isSelected: boolean;

		// Currently, because the CMP project settings are not part of the SPA, the whole sidebar reloads everytime a menu item
		// is clicked -> As part of the Lunar Project Settings improvements, we're scrolling selected items into view once the
		// page reloads.
		//
		// We're setting a timeout to wait for the event loop to complete prior to scrolling.
		useEffect(() => {
			// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
			if (isSelected && ref?.current != null) {
				setTimeout(() => {
					// using ref?.current doesn't seem to work specifically here
					// Flow complianed about this part here specifically :/
					ref &&
						// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
						ref.current != null &&
						// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
						ref.current.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
				});
			}
		}, [
			selectedOn,
			ref,
			// @ts-expect-error - Variable 'isSelected' is used before being assigned.
			isSelected,
		]);

		if (typeof selectedOn === 'function') {
			isSelected =
				route != null &&
				// @ts-expect-error - Property 'search' is missing in type 'URLParse' but required in type 'MatcherLocation'.
				selectedOn({ ...location, query }, urlParse(href || '/', true));
		} else {
			isSelected =
				Boolean(selectedOn) &&
				route != null &&
				selectedOn != null &&
				selectedOn.includes(route.name);
		}

		const theme = useSidebarTheme();

		const cssFn = useCallback(
			(currentState: LegacyEmotionCssObject & AnyState) => ({
				...(outsideCssFn && outsideCssFn(currentState)),
				...customCssFn(currentState),
				...getCustomThemeCss(theme),
			}),
			[theme, outsideCssFn],
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const customProps: any = {
			...rest,
			component: WrapperComponent,
			cssFn,
			href,
			isSelected,
			onClick: onClickHandler,
			overrides,
			ref,
			useButtonLink,
			'aria-current': isSelected ? 'page' : undefined,
		};

		return overrides?.CustomItem?.render(customProps) || <CustomItem {...customProps} />;
	},
);

// this should be same as ButtonItem in '@atlaskit/menu'
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonItem = styled.button({
	backgroundColor: 'transparent',
	border: 0,
	outline: 0,
	margin: 0,
	width: '100%',
});
