import { CONFIGURABLE_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { ConfigurableMenuId } from '../../types.tsx';

export const convertToConfigurableMenuId = (menuId: string): ConfigurableMenuId => {
	switch (menuId) {
		case 'jira.sidebar.apps':
			return CONFIGURABLE_MENU_ID.APPS;
		case 'jira.sidebar.assets':
			return CONFIGURABLE_MENU_ID.ASSETS;
		case 'jira.sidebar.dashboards':
			return CONFIGURABLE_MENU_ID.DASHBOARDS;
		case 'jira.sidebar.filters':
			return CONFIGURABLE_MENU_ID.FILTERS;
		case 'jira.sidebar.goals':
			return CONFIGURABLE_MENU_ID.GOALS;
		case 'jira.sidebar.operations':
			return CONFIGURABLE_MENU_ID.OPERATIONS;
		case 'jira.sidebar.overviews':
			return CONFIGURABLE_MENU_ID.OVERVIEWS;
		case 'jira.sidebar.plans':
			return CONFIGURABLE_MENU_ID.PLANS;
		case 'jira.sidebar.projects':
			return CONFIGURABLE_MENU_ID.PROJECTS;
		case 'jira.sidebar.recent':
			return CONFIGURABLE_MENU_ID.RECENT;
		case 'jira.sidebar.starred':
			return CONFIGURABLE_MENU_ID.STARRED;
		case 'jira.sidebar.teams':
			return CONFIGURABLE_MENU_ID.TEAMS;
		case 'jira.sidebar.roadmaps':
			return CONFIGURABLE_MENU_ID.ROADMAPS;
		case 'jira.sidebar.customerService':
			return CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE;
		case 'jira.sidebar.customerServiceAnalytics':
			return CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE_ANALYTICS;
		case 'jira.sidebar.globalApp.assets':
			return CONFIGURABLE_MENU_ID.GLOBAL_APP_ASSETS;
		case 'jira.sidebar.globalApp.goals':
			return CONFIGURABLE_MENU_ID.GLOBAL_APP_GOALS;
		default:
			throw new Error(`Invalid menuId: ${menuId}`);
	}
};
