import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const customerSupportEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-software-customer-support-entrypoint" */ './src'),
	),
	getPreloadProps: () => {
		return {};
	},
});
