import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { helpCenterSettingsAgentStudioActionsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsAgentStudioActionsRoute.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { agentStudioNavigation } from './common/constants.tsx';

import {
	LazyAgentStudioActionsPage,
	AgentStudioActionsComponent,
} from './ui/agent-studio/index.tsx';

export const helpCenterSettingsAgentStudioActionsRouteEntry = createEntry(
	helpCenterSettingsAgentStudioActionsRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.agent-studio-actions',
		component: componentWithFG(
			'enable_support_center_agent_studio_page',
			AgentStudioActionsComponent,
			ErrorPagesNotFound,
		),
		layout: serviceProjectLayout,
		navigation: agentStudioNavigation,
		resources: [...getServiceDeskSettingsResources()],
		forPaint: [LazyAtlassianNavigation, LazyAgentStudioActionsPage],
		earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	}),
);
