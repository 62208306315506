import React from 'react';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import { AssetsIcon } from '@atlaskit/temp-nav-app-icons/assets';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { AssetsUpsellButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-assets/src/ui/assets-app-item/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { MenuLinkItem } from '@atlassian/navigation-system';
import type { AppProps } from '../types.tsx';
import messages from './messages.tsx';

export function Assets({ onClick }: AppProps) {
	const { formatMessage } = useIntl();
	const url = '/jira/assets';

	const appEditions = useAppEditions();
	const isNotJsmPremium = appEditions.serviceDesk !== PREMIUM_EDITION;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSiteAdmin = useIsSiteAdmin();

	if (isNotJsmPremium) {
		fireTrackAnalytics(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
			serviceDeskEdition: appEditions.serviceDesk,
			isSiteAdmin,
		});

		return (
			<AssetsUpsellButton
				configurableMenuId={L1_MENU_ID.GLOBAL_APP_ASSETS}
				lozengeText={formatMessage(messages.lozenge)}
			/>
		);
	}

	return (
		<MenuLinkItem
			href={url}
			elemAfter={<LinkExternalIcon label="" />}
			elemBefore={<AssetsIcon />}
			target="_blank"
			onClick={onClick}
			testId="navigation-apps-sidebar-nav4-sidebars-content-global-app-shortcuts.ui.assets.menu-link-item"
		>
			{formatMessage(messages.assets)}
		</MenuLinkItem>
	);
}
