import React from 'react';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import { GoalsIcon } from '@atlaskit/temp-nav-app-icons/goals';
import { useIntl } from '@atlassian/jira-intl';
import { MenuLinkItem } from '@atlassian/navigation-system';
import type { AppProps } from '../types.tsx';
import messages from './messages.tsx';

export function Goals({ onClick }: AppProps) {
	const { formatMessage } = useIntl();
	const url = 'https://home.atlassian.com/goals';

	return (
		<MenuLinkItem
			href={url}
			elemAfter={<LinkExternalIcon label="" />}
			elemBefore={<GoalsIcon />}
			target="_blank"
			onClick={onClick}
			testId="navigation-apps-sidebar-nav4-sidebars-content-global-app-shortcuts.ui.goals.menu-link-item"
		>
			{formatMessage(messages.goals)}
		</MenuLinkItem>
	);
}
